import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ApplySection: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('jobs');

  return (
    <>
      <h2 className="m-0 text-base font-bold">{t('render.apply.title')}</h2>
      <h3 className="mb-8 mt-2 p-0 text-base font-medium">{t('render.apply.subtitle')}</h3>
    </>
  );
};

export default ApplySection;
