import * as React from 'react';

function useElementOnScreen<U extends HTMLElement>(
  options?: IntersectionObserverInit,
  callback?: (ref: React.RefObject<U>, isInView: boolean) => void,
): [React.RefObject<U>, boolean] {
  const observedElement = React.useRef<U>(null);
  const [isVisible, setVisible] = React.useState(false);

  const observerCallback = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (isVisible !== entry.isIntersecting) {
        setVisible(entry.isIntersecting);
        if (callback) callback(observedElement, entry.isIntersecting);
      }
    },
    [callback, isVisible],
  );

  React.useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    const element = observedElement.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [observerCallback, options]);

  return [observedElement, isVisible];
}

export default useElementOnScreen;
