import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';
import { AllMdxJob, graphql2job, PageContextProps, SiteQueryProps } from '../../utils/graphql';
import DefaultLayout from '../layouts/default-layout';
import JobsContent from './JobsContent';

type JobsQueryProps = {
  site: SiteQueryProps;
  allMdx: AllMdxJob;
};

type JobsProps = PageProps<JobsQueryProps, PageContextProps>;

const Jobs: React.VoidFunctionComponent<JobsProps> = ({
  data: {
    allMdx: { nodes },
  },
  pageContext,
}) => {
  const { t } = useTranslation('jobs');

  const jobs = nodes.map(graphql2job);

  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
    >
      <JobsContent jobs={jobs} />
    </DefaultLayout>
  );
};

export default Jobs;
