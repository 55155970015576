import React from 'react';
import { BLUE_ON_WHITE } from './Colors';

type TagsProps = {
  tags: string[];
  tagClassName?: string;
};

const defaultThemeclass = BLUE_ON_WHITE;

const Tags: React.VoidFunctionComponent<TagsProps> = ({
  tags,
  tagClassName = defaultThemeclass,
}) => {
  return (
    <>
      {tags.map((tag) => (
        <p key={tag} className={`${tagClassName} my-2 mr-4 px-3 py-1 font-medium md:m-0 md:ml-4`}>
          {`#${tag}`}
        </p>
      ))}
    </>
  );
};

export default Tags;
