import { graphql } from 'gatsby';
import Jobs from '../components/jobs/Jobs';

export default Jobs;

export const query = graphql`
  query ($locale: String!) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    allMdx(
      filter: { fields: { source: { eq: "jobs" }, locale: { eq: $locale }, slug: { ne: null } } }
      sort: { fields: [fields___date, fields___slug], order: DESC }
      limit: 1000
    ) {
      nodes {
        frontmatter {
          title
          domains
          cities
          contracts
        }
        fields {
          slug
          date
          locale
        }
        body
      }
    }
  }
`;
