import { TFunction } from 'react-i18next';

const translateLabel = (prefix: string, t: TFunction) => (label: string) => {
  const translateKey = `${prefix}.${label.toLowerCase()}`;
  const translatedLabel = t(translateKey);
  /* istanbul ignore next */ // Return always 'label' with i18n mock test cases
  return translatedLabel === translateKey ? label : translatedLabel;
};

export default translateLabel;
